.MainPage {
    margin-top: 58px;

    @media screen and (max-width: 480px)  {
        margin-top: 30px;
    }

    &__firstblock {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 50px;
        gap: 20px;
    }

    &__wrapper {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-weight: 700;
        color: var(--color-main);
        margin-bottom: 18px;

        h1 {
            font-size: 48px;

            @media screen and (max-width: 480px)  {
                font-size: 36px;
            }
        }

        h2 {
            font-size: 36px;

            @media screen and (max-width: 480px)  {
                font-size: 24px;
            }
        }



        span {
            color: var(--color-primary);
        }
    }

    &__subtitle {
        margin-bottom: 48px;
        font-size: 21px;

        @media screen and (max-width: 480px)  {
            font-size: 16px;
        }
    }

    &__buttons {
        display: flex;

        @media screen and (max-width: 480px)  {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__button {
        margin-right: 10px;
    }

    &__blocks {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 10px;

        @media screen and (max-width: 1024px)  {
            display: none;
        }

        &_block {
            width: 100px;
            height: 100px;
            border-radius: var(--radius-l);

            transition: transform .2s ease-in-out, border-color .2s ease-in-out, background-color .2s ease-in-out;

            &:hover {
                transform: scale(0.9);
            }

            &Outlined {
                border: 4px solid var(--color-primary);

                &:hover {
                    border-color: var(--color-primary-hover);
                }
            }

            &Fulled {
                background-color: var(--color-primary);

                &:hover {
                    background-color: var(--color-primary-hover);
                }
            }
        }
    }

}

.title {
    font-size: 40px;
    margin-top: 10px;
}
