.Exchange {
    max-width: 400px;
    //padding: 32px;
    //background: var(--color-white);
    //box-shadow: 0 8px 8px rgba(0, 0, 0, 0.06), 0 16px 32px rgba(0, 0, 0, 0.06);
    //border-radius: var(--radius-xl);
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;

    //@media screen and (max-width: 1150px)  {
    //    flex-direction: column;
    //    align-items: stretch;
    //}
}

.wrapperBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-grow: 1;
    gap: 15px;

    //@media screen and (max-width: 768px)  {
    //    flex-direction: column;
    //}
}

.title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-main);
    margin-bottom: 18px;

    @media screen and (max-width: 480px)  {
        font-size: var(--font-size-l);
    }
}

.swapBtn {
    padding: 5px 10px;
}

.inputWrapper {
    position: relative;
    width: 100%;
}

.dropdown {
    position: absolute !important;
    right: 0;
    bottom: 0;
    width: 120px !important;

    & > div:first-child {
        border-color: transparent;
    }
}

.payments {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    gap: 15px;

    //@media screen and (max-width: 480px)  {
    //    flex-direction: column;
    //    align-items: stretch;
    //}
}

.paymentsDropdown {
    width: 100% !important;
    & > div {
        background-color: #fff;
    }
    //@media screen and (max-width: 480px) {
    //
    //}
}

.buyCrypto {
    flex-grow: 1;
}
