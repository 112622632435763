.PortfolioBlock {
    margin-top: 50px;
}

.title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-main);
    margin-bottom: 18px;
}

.blocks {
    display: flex;
    gap: 32px;
}
