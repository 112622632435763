.button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 88px;
    min-height: 36px;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    font-size: var(--font-size-l);
    cursor: pointer;
    color: #fff;
    transition: all 0.3s ease;
    outline: none;
    padding: 14px 33px;

    &.text {
        color: var(--color-primary);
        background-color: transparent;

        &:hover {
            background-color: rgba(252, 44, 1, 0.04);
        }

        .ripple {
            background-color: rgba(252, 44, 1, 0.5);
        }
    }

    &.contained {
        background-color: var(--color-primary);

        &:hover {
            background-color: var(--color-primary-hover);
        }

        .ripple {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }

    &.outlined {
        color: var(--color-primary);
        background-color: transparent;
        border: 1px solid var(--color-primary);

        &:hover {
            background-color: rgba(252, 44, 1, 0.04);
        }

        .ripple {
            background-color: rgba(252, 44, 1, 0.5);
        }
    }

    &:focus {
        outline: none;
    }

    position: relative;
    overflow: hidden;

    .ripple {
        position: absolute;
        width: 10px;
        height: 10px;
        transform: translate(-50%, -50%) scale(0);
        border-radius: 50%;
        animation: ripple 0.5s linear;
        pointer-events: none;
    }

    @keyframes ripple {
        to {
            transform: translate(-50%, -50%) scale(15);
            opacity: 0;
        }
    }
}
