.BeginnerBlock {
    margin-top: 50px;
}

.title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-bold);
    color: var(--color-main);
    margin-bottom: 18px;
}

.blocks {
    display: flex;
    gap: 32px;
}

.block {
    background-color: #F0F0F0;
    border-radius: calc(var(--radius-xl) * 2);
    height: 500px;
    width: 100%;
}
