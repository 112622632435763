.app_light_theme {
    --color-primary: #FC2C01;
    --color-primary-hover: #EA2800;

    --background-color-body: #FAFAFA;

    --skeleton-color: #F0F0F0;

    --skeleton-shadow: rgba(0, 0, 0, 0.1);
}
