:root {
    --font-family-main: 'Inter', sanf-serif;

    --font-size-s: 13px;
    --font-size-m: 15px;
    --font-size-l: 17px;
    --font-size-xl: 21px;
    --font-size-xxl: 32px;

    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-weight-extrabold: 900;

    --color-white: #FFFFFF;
    --color-black: #222222;

    --radius-m: 4px;
    --radius-l: 8px;
    --radius-xl: 12px;

    // DEVICES
    --device-xs: 320px;
    --device-s: 480px;
    --device-m: 768px;
    --device-l: 1024px;
    --device-xl: 1440px
}
