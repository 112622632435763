.Skeleton {
    position: relative;
    width: 100%;
    height: 50px;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 var(--skeleton-shadow);

    &::before {
        position: absolute;
        top: 0;
        left: -150px;
        display: block;
        width: 80%;
        height: 100%;
        content: '';
        background: linear-gradient(to right, transparent 0%, var(--skeleton-color) 50%, transparent 100%);
        animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
    }
}

@keyframes load {
    from {
        left: -150px;
    }

    to {
        left: 100%;
    }
}
