@import "reset";
@import "variables/global";
@import "theme/light";

body {
    font-family: var(--font-family-main);
    background-color: var(--background-color-body);
}

.app {
    max-width: var(--device-xl);
    margin: 0 auto;
    padding: 0 20px;
}
