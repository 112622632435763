.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}

.overlayOpen {
    opacity: 1;
    pointer-events: auto;
}

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    background-color: white;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.wrapperOpen {
    transform: translateX(0);
}

.content {
    height: 100%;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    height: 50px;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--color-black);
        transform-origin: 14px;
        transition: transform 0.3s ease-out;

        &:first-child {
            transform: rotate(45deg);
        }

        &:last-child {
            transform: rotate(-45deg);
        }
    }
}

.body {
    display: flex;
    flex-direction: column;

    a {
        font-weight: 400;
        font-size: var(--font-size-m);
        line-height: 150%;
        color: var(--color-black);
        margin-bottom: 10px;
    }
}

.bodyButtons {
    margin-top: 30px;
}
//
//@media only screen and (max-width: 767px) {
//    .sidebar__wrapper {
//        width: 100%;
//    }
//}
