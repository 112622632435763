.Dropdown {
    position: relative;
    width: 200px;
}

.selected {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    min-height: 49px;

    &:after {
        position: absolute;
        content: "";
        width: 10px;
        height: 10px;
        right: 13px;
        border-top: 2px solid var(--color-primary);
        border-right: 2px solid var(--color-primary);
        transform: rotate(135deg);
        transition: transform .2s ease-in-out;
    }

    img {
        margin-right: 10px;
    }

    &_open {
        &:after {
            transform: rotate(-45deg);
        }
    }
}

.title {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-s);
    margin-bottom: 5px;
    display: inline-block;
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow-y: auto;
    max-height: 200px;
    background-color: var(--color-white);
    z-index: 2;
}

.option {
    padding: 10px;
    cursor: pointer;

    &:hover {
        background-color: #eee;
    }
}

.image {
    width: 27px;
    height: auto;
    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
    }
}

.content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.label {
    font-size: var(--font-size-l);
    color: #333;
}
