.Input {
    display: flex;
    flex-direction: column;
}

.label {
    font-weight: var(--font-weight-bold);
    margin-bottom: 5px;
    font-size: var(--font-size-s);
}

.field {
    padding: 10px;
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
    border: 1px solid #D9D9D9;
    border-radius: var(--radius-m);
    height: 100%;
    min-height: 49px;
}
