.Navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__menu {
        display: flex;
        align-items: center;
        margin-left: 20px;

        @media screen and (max-width: 1024px)  {
            display: none;
        }

        &_item {
            display: inline-block;
            margin-left: 20px;

            a {
                color: var(--color-black);

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__burger {
        display: none;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        @media screen and (max-width: 1024px)  {
            display: flex;
        }

        span {
            display: block;
            width: 30px;
            height: 2px;
            background-color: var(--color-black);
            margin-bottom: 5px;

        }
    }

    &__buttons {
        @media screen and (max-width: 1024px)  {
            display: none;
        }
    }

    &__button {
        margin-left: 5px;
    }
}
