*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

button,
input,
textarea,
select {
    font: inherit;
}

